var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "virtual-cards" }, [
    _vm._m(0),
    _c("div", { staticClass: "virtual-cards__form" }, [
      _c(
        "form",
        {
          staticClass: "virtual-cards__form__fieldset",
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.unlock.apply(null, arguments)
            },
          },
        },
        [
          _vm._m(1),
          _c("v-textbox", {
            attrs: {
              mask: ["###.###.###-##", "##.###.###/####-##"],
              name: "account.cpf",
              label: "CPF ou CNPJ",
              placeholder: "000.000.000-00 ou 00.000.000/0000-00",
              type: "text",
              validate: "required",
              required: "",
            },
            model: {
              value: _vm.document,
              callback: function ($$v) {
                _vm.document = $$v
              },
              expression: "document",
            },
          }),
          _c("v-textbox", {
            attrs: {
              label: "Data de nascimento ou Abertura da Empresa",
              mask: "##/##/####",
              name: "account.birthDate",
              placeholder: "dd/mm/aaaa",
              type: "text",
              validate: "required|date_format:DD/MM/YYYY",
              required: "",
            },
            model: {
              value: _vm.birthDate,
              callback: function ($$v) {
                _vm.birthDate = $$v
              },
              expression: "birthDate",
            },
          }),
          _c("v-button", {
            staticClass: "virtual-cards__form__fieldset__submit",
            attrs: {
              icon: "arrow-right",
              bgColor: "blue",
              radius: true,
              rebranding: true,
              label: "Entrar",
            },
          }),
        ],
        1
      ),
      _vm._m(2),
    ]),
    _vm._m(3),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "virtual-cards__header" }, [
      _c("div", { staticClass: "virtual-cards__header--small" }, [
        _c(
          "a",
          {
            staticClass: "virtual-cards__header__logo",
            attrs: { href: "https://www.onlypay.com.br" },
          },
          [
            _c("img", {
              attrs: { src: require("@/assets/img/logo_white.svg") },
            }),
          ]
        ),
      ]),
      _c("div", { staticClass: "virtual-cards__header--large" }, [
        _c(
          "a",
          {
            staticClass: "virtual-cards__header__logo",
            attrs: { href: "https://www.onlypay.com.br" },
          },
          [
            _c("img", {
              attrs: { src: require("@/assets/img/logo_white.svg") },
            }),
          ]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "virtual-cards__form__fieldset__message" }, [
      _c("span", [_vm._v("Olá!")]),
      _vm._v(
        " Digite seu CPF ou CNPJ e sua data de nascimento ou abertura para desbloquear seu cartão:\n      "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "virtual-cards__form__footer" }, [
      _vm._v("\n      © 2023 "),
      _c("a", { attrs: { href: "https://www.onlypay.com.br" } }, [
        _vm._v("OnlyPay"),
      ]),
      _vm._v(" - Soluções de pagamento\n    "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "virtual-cards__aside" }, [
      _c("h2", { staticClass: "virtual-cards__aside__text-1" }, [
        _vm._v("Bem vindo à Only"),
        _c("strong", [_vm._v("Pay")]),
      ]),
      _c("h3", { staticClass: "virtual-cards__aside__text-2" }, [
        _vm._v("Simples "),
        _c("span", [_vm._v("assim!")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }