<script>
import Swal from 'sweetalert2';
import VButton from '@/components/Button.vue';
import VTextbox from '@/components/Textbox.vue';
// eslint-disable-next-line import/no-cycle
import { setUserData } from '@/helpers/user';

export default {
  components: {
    VButton,
    VTextbox
  },
  data() {
    return {
      document: '',
      birthDate: ''
    };
  },
  computed: {
    token() {
      return this.$route.params.token;
    },
  },
  methods: {
    unlock() {
      if (!this.$store.state.formLoading) {
        this.$store.commit('formLoading', true)
        this.$http.post(`${process.env.VUE_APP_HUB_BASE_URL2}/users/unlock-virtual-card`, {
          document: this.document.replace(/\D/g, ''),
          birthDate: this.$moment(this.birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
          token: this.token
        })
          .then(({ data }) => {
            if (data.success) {
              setUserData(JSON.stringify(data.user));
              this.$store.commit('setUser', data.user);
              // this.$cookies.set('token', data.token, '1d');
              this.$router.push({ name: 'signUp', query: { token: this.token } });
            } else this.showNotificationInvalidToken()
          })
          .catch(err => {
            this.$validator.errors.add({ msg: err, field: 'email' })
            this.showNotificationInvalidToken()
          })
          .then(() => this.$store.commit('formLoading', false))
      }
    },
    showNotificationInvalidToken() {
      Swal.fire({
        title: 'Link inválido ou vencido',
        html: `<p style="text-align: left; font-size: 16px;">
          O seu link para desbloqueio está vencido ou inválido.
          Caso já tenha usado esse link anteriormente e desbloqueado
          o seu cartão, acesse o site para ver seu saldo e utilizar seu cartão.
          Caso não tenha desbloquado seu cartão ainda, por favor, entre em contato com o nosso atendimento.
        <p>`,
        confirmButtonText: 'Entendi'
      })
    }
  },
}
</script>

<template>
  <div class="virtual-cards">
    <div class="virtual-cards__header">
      <div class="virtual-cards__header--small">
        <a href="https://www.onlypay.com.br" class="virtual-cards__header__logo">
          <img src="~@/assets/img/logo_white.svg" />
        </a>
      </div>
      <div class="virtual-cards__header--large">
        <a href="https://www.onlypay.com.br" class="virtual-cards__header__logo">
          <img src="~@/assets/img/logo_white.svg" />
        </a>
      </div>
    </div>
    <div class="virtual-cards__form">
      <form v-on:submit.prevent="unlock"  class="virtual-cards__form__fieldset" >
        <p class="virtual-cards__form__fieldset__message">
          <span>Olá!</span> Digite seu CPF ou CNPJ e sua data de nascimento ou abertura para desbloquear seu cartão:
        </p>
        <v-textbox
          v-model="document"
          :mask="['###.###.###-##','##.###.###/####-##']"
          name="account.cpf"
          label="CPF ou CNPJ"
          placeholder="000.000.000-00 ou 00.000.000/0000-00"
          type="text"
          validate="required"
          required/>
        <v-textbox
          v-model="birthDate"
          label="Data de nascimento ou Abertura da Empresa"
          mask="##/##/####"
          name="account.birthDate"
          placeholder="dd/mm/aaaa"
          type="text"
          validate="required|date_format:DD/MM/YYYY"
          required/>
        <v-button class="virtual-cards__form__fieldset__submit" icon="arrow-right" bgColor="blue" :radius="true" :rebranding="true" label="Entrar"/>
      </form>
      <div class="virtual-cards__form__footer">
        &copy; 2023 <a href="https://www.onlypay.com.br">OnlyPay</a> - Soluções de pagamento
      </div>
    </div>
    <div class="virtual-cards__aside">
      <h2 class="virtual-cards__aside__text-1">Bem vindo à Only<strong>Pay</strong></h2>
      <h3 class="virtual-cards__aside__text-2">Simples <span>assim!</span></h3>
    </div>
  </div>
</template>

<style lang="postcss">
.textbox {
  display: flex;
  flex-direction: column;
  &:not(:last-of-type) {
    margin-bottom: 1.5rem;
  }
  &__label {
    color: var(--color-blue-onlypay);
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 0.3rem;
  }
  &__input {
    border: none;
    background-color: var(--color-white);
    padding: 1.1rem 2rem 1rem 1.5rem;
    &:-webkit-autofill {
      transition: background-color 5000s ease-in-out 0s;
    }
  }
}
</style>

<style lang="postcss" scoped>
.no-box-shadow {
  box-shadow: none!important;
}
.no-padding{
  padding: 0!important;
}
.virtual-cards {
  height: 100%;
  padding-top: 100px;
  position: relative;
  @media (min-width: 992px) {
    display: flex;
  }
  &__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100px;
    background: var(--color-blue-onlypay);
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 99;
    &__logo {
      size: 8rem 4rem;
      display: flex;
    }
    &--small {
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--color-white);
      width: 100%;
      height: 6rem;
      /* height: 12vh; */
      @media (min-width: 992px) {
        display: none;
      }
    }
    &--large {
      display: none;
      padding: 0 0 0 1.5rem;
      @media (min-width: 992px) {
        display: flex;
      }
    }
  }
  &__form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    background: rgba(255, 255, 255, 0.88);
    position: relative;
    z-index: 10;
    @media (min-width: 992px) {
      background: #EDEEF0;
      box-shadow: 1px 0 1px 0 rgba(0, 0, 0, .2);
      width: 28rem;
    }
    &__fieldset {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-grow: 1;
      padding: 0 1.5rem;
      @media (max-width: 992px) and (orientation: landscape) {
        padding: 1.5rem;
      }
      &__message {
        display: flex;
        flex-direction: column;
        text-transform: uppercase;
        color: var(--color-blue-onlypay);
        font-size: 1.3rem;
        font-weight: bold;
        line-height: 1.6rem;
        margin-bottom: 2rem;
        padding-right: 3rem;

        span {
          display: flex;
          font-size: 3.5rem;
          line-height: 5.5rem;
        }
        @media (max-width: 992px) and (orientation: landscape) {
          display: none;
        }
        @media (min-width: 992px) {
          padding-right: 0rem;
        }
      }
      &__submit {
        justify-content: center;
        align-items: center;
        margin-top: 2rem;
        @media (min-width: 992px) {
          display: flex;
          width: 45%;
          margin-top: 2rem;
        }
      }
    }
    &__footer {
      border-top: .0625rem solid var(--color-grey-light);
      font-size: .625rem;
      margin: 0 1.5rem;
      padding: 1rem 0;
    }
  }
  &__aside {
    background-image: url('~@/assets/img/bg_card_unlock.png');
    background-position: center center;
    background-size: cover;
    color: var(--color-white);
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    @media (min-width: 992px) {
      overflow: hidden;
      width: unset;
      height: unset;
      top: unset;
      left: unset;
      position: relative;
    }
    &__text-1 {
      display: none;
      font-size: 3rem;
      font-weight: 200;
      margin-left: -.125rem;
      padding: .75rem 4rem;
    }
    &__text-2 {
      display: none;
      /* color: var(--color-green);
      font-size: 1.5rem;
      font-weight: 300;
      padding: .75rem 4rem; */
      font-family: 'Trash Hand';
      font-size: 8rem;
      line-height: 6rem;
      opacity: .4;
      position: absolute auto .25em .3em auto;
      transform: rotate(-15deg);
      & span {
        display: block;
        margin-left: 5.5rem;
      }
    }
  }
}
</style>
